import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  IconButton,
  Select,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { FaEdit } from 'react-icons/fa';
import { AddressType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { useStore } from '../../hooks/useStore';
import DefaultProfile from '../../assets/png/default-profile.png';
import ConfirmationModal from '../modals/ConfirmationModal';

import InfoDisplay from './InfoDisplay';
import EnableDisableSeller from './user/EnableDisableSeller';
import ReviewUserRequest from './user/ReviewUserRequest';

type UserDetailsProps = {
  onDelete: () => Promise<void>;
};

const UserDetails = ({ onDelete }: UserDetailsProps) => {
  const {
    userStore: { displayedUser, updateSellerFee },
  } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingSellerFee, setIsEditingSellerFee] = useState(false);
  const [sellerFee, setSellerFee] = useState(displayedUser.sellerFee);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const userInfo = [
    { title: 'Email:', value: displayedUser.email || 'No data' },
    { title: 'Amount Spent:', value: displayedUser.amountSpent || 'No data' },
    { title: 'Amount Sold:', value: displayedUser.amountSold || 'No data' },
    { title: 'Signed Up Date:', value: displayedUser.createdAt || 'No data' },
    { title: 'Seller stripe account id:', value: displayedUser.stripeUserId || 'No data' },
    { title: 'Seller customer account id:', value: displayedUser.stripeCustomerId || 'No data' },
    { title: 'Payment Failed Strikes:', value: displayedUser.paymentFailedStrikes || 'No data' },
  ];

  const handleDelete = async () => {
    await onDelete();
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleEditSellerFee = () => {
    setIsEditingSellerFee(true);
  };

  const handleSaveSellerFee = async () => {
    try {
      setLoading(true);
      await updateSellerFee(sellerFee);
      setIsEditingSellerFee(false);
      toast({
        title: 'Success',
        description: 'Seller fee updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error',
        description: 'Failed to update seller fee',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSellerFeeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSellerFee(parseInt(event.target.value));
  };

  const shippingInfo = useMemo(() => {
    if (!displayedUser.shippingInfo || displayedUser.shippingInfo.length === 0) {
      return null;
    }

    // for older users that might accidentally have multiple addresses of the same type
    const latestDefaultAddress = [...displayedUser.shippingInfo]
      .reverse()
      .find((info) => info.type === AddressType.DEFAULT);

    const latestReturnAddress = [...displayedUser.shippingInfo]
      .reverse()
      .find((info) => info.type === AddressType.RETURN_ADDRESS);

    return { latestDefaultAddress, latestReturnAddress };
  }, [displayedUser.shippingInfo]);

  const renderShippingInfo = (info: typeof displayedUser.shippingInfo[0]) => (
    <VStack align="start" spacing={1} key={info.type}>
      <Text fontWeight="bold">{info.type === AddressType.DEFAULT ? 'Default Address' : 'Return Address'}</Text>
      <Text>{`${info.firstName} ${info.lastName}`}</Text>
      <Text>{info.addressLineOne}</Text>
      {info.addressLineTwo && <Text>{info.addressLineTwo}</Text>}
      <Text>{`${info.city}, ${info.state} ${info.zipCode}`}</Text>
      <Text>{info.country}</Text>
      <Text>{`${info.countryCode} ${info.phoneNumber}`}</Text>
    </VStack>
  );

  return (
    <VStack align="center" spacing={4}>
      <Avatar size="xl" src={displayedUser.avatar || DefaultProfile} />
      <Heading mb={4}>{displayedUser.userName || 'No username'}</Heading>
      {userInfo.map((info, index) => (
        <InfoDisplay key={index} title={info.title} value={info.value} />
      ))}
      {displayedUser.stripeUserId && (
        <HStack w="100%" justify="center" p={2} gap={10}>
          <Text fontWeight="bold">Seller Fee:</Text>
          {isEditingSellerFee ? (
            <>
              <Select value={sellerFee} onChange={handleSellerFeeChange}>
                {Array.from({ length: 16 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}%
                  </option>
                ))}
              </Select>
              <Button onClick={handleSaveSellerFee} isLoading={loading}>
                Save
              </Button>
            </>
          ) : (
            <HStack>
              <Text pr={10}>{sellerFee}%</Text>
              <IconButton aria-label="Edit seller fee" icon={<FaEdit />} onClick={handleEditSellerFee} />
            </HStack>
          )}
        </HStack>
      )}
      {shippingInfo && (
        <VStack w="100%" align="start" spacing={4} p={4} border="1px solid" borderColor="gray.200" borderRadius="md">
          <Heading size="md">Shipping Information</Heading>
          {shippingInfo.latestDefaultAddress && (
            <Box>
              {renderShippingInfo(shippingInfo.latestDefaultAddress)}
              <Divider my={2} />
            </Box>
          )}
          {shippingInfo.latestReturnAddress && renderShippingInfo(shippingInfo.latestReturnAddress)}
        </VStack>
      )}
      {displayedUser.pendingRequest && <ReviewUserRequest />}
      {displayedUser.stripeUserId && <EnableDisableSeller />}
      <Button onClick={handleClick} w="100%" bg="red.400" py="25px">
        Delete user
      </Button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={'Delete user?'}
        subtitle={'This action cannot be undone'}
        cancelActionLabel={'Cancel'}
        confirmActionLabel={'Delete'}
        onCancel={handleModalClose}
        onConfirm={handleDelete}
      />
    </VStack>
  );
};

export default observer(UserDetails);
